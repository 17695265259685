import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Seo from "../components/seo"
import { Link } from "gatsby"

export default function SingleRecipe({ data }) {
    return (
        <Layout>
            <Seo
                title={data.contentfulRecipe.title}
            />
            <div className="row">
                <div className="col-12 col-md-6 mx-md-auto border shadow rounded p-4">
                    <Link to="/" className="text-dark"><i className="fas fa-arrow-left small"/> Retour à la liste des recettes</Link>
                    <h1 className=''>{ data.contentfulRecipe.title }</h1>
                    <div className="">
                        <div className='my-4'>
                            <Img className=''
                                 fluid={data.contentfulRecipe.image.fluid}
                                 key={data.contentfulRecipe.image.fluid.src}
                                 alt={data.contentfulRecipe.image.title}/>
                        </div>
                        <h2 className='mb-3'>Ingrédients</h2>
                        <ul className=''>
                            {data.contentfulRecipe.ingredients.map((node, i) => {
                                return <li className='ml-4' key={i}>
                                    {node}
                                </li>
                            })}
                        </ul>
                        <h2 className='my-3'>Instructions</h2>
                        <div className="" dangerouslySetInnerHTML={{ __html: data.contentfulRecipe.instructions.childContentfulRichText.html}}>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ContentfulRecipeBySlug($id: String!) {
        contentfulRecipe(id: { eq: $id }) {
             title
             image { 
                title
                fluid(maxWidth: 1500) {
                   ...GatsbyContentfulFluid
                }
             }
             quantity
             ingredients
             instructions {
                  childContentfulRichText {
                    html
                  }
             }
        }
    }
`